import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withLayout from './withLayout';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import ComparisonChart from './ComparisonChart';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import TuneSharpIcon from '@material-ui/icons/TuneSharp';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {
  addGrowths,
  addRollingGrowths,
  addPerMillion,
  getTranslationKeyList,
  getTranslationKeyListFromArray,
  getTranslationFromKeyTo,
} from '../utils/dataUtils';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import matchSorter from 'match-sorter';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const DRAWER_WIDTH = 300;

const useStyles = makeStyles(theme => {
  return {
    drawer: {
      width: DRAWER_WIDTH,
      // TODO: Parametrizar
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      padding: theme.spacing(0, 3),
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      // To support dense top toolbar...Parametrize this
      minHeight: 48,
    },
    contentArea: {
      marginRight: DRAWER_WIDTH,
    },
    container: {
      maxWidth: 798,
      paddingTop: theme.spacing(3),
    },
    mainTitle: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(6),
    },
    dividerSmall: {
      marginBottom: theme.spacing(2),
    },
    chartTitle: {
      marginBottom: theme.spacing(2),
    },
    paper: {
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
    paperMobile: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(1),
    },
    controlGroup: {
      marginBottom: theme.spacing(4),
    },
    secondaryControlGroup: {
      marginBottom: theme.spacing(3),
    },
    sideBySideFormControl: {
      marginBottom: theme.spacing(4),
    },
    flexInput: {
      display: 'flex',
    },
    baseTypeSelect: {
      marginRight: theme.spacing(3),
    },
    topContainer: {
      marginBottom: theme.spacing(3),
    },
    baseValueInputInstructions: {
      display: 'block',
      marginTop: theme.spacing(1),
    },
    countrySelector: {
      marginBottom: theme.spacing(2),
    },
    fab: {
      position: 'fixed',
      zIndex: theme.zIndex.drawer,
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
  };
});

const BASE_ANCHOR = {
  fromEnd: 0,
  fromCase: 1,
};

const BASE_CASETYPE = {
  death: 'deaths',
  confirmed: 'confirmed',
};

function computeChartDataFromEndDate(
  countryKeysList,
  seriesData,
  argumentKey,
  period,
  translateKey,
) {
  // TODO: Mejorar esta operación
  let listOfTimeSeries = countryKeysList.map(key =>
    seriesData[key].filter((val, i, arr) => {
      if (period > 0) {
        return i > arr.length - period;
      }
      return true;
    }),
  );

  let maxLenList = [];
  for (let series of listOfTimeSeries) {
    maxLenList = series.length > maxLenList ? series : maxLenList;
  }

  let list = [];
  for (let i = 0; i < maxLenList.length; i++) {
    // TODO: Add option for numbered days
    let day = { date: maxLenList[i].date };
    for (let j = 0; j < listOfTimeSeries.length; j++) {
      let countryKey = countryKeysList[j];
      if (translateKey) {
        const translated = getTranslationFromKeyTo(countryKey, translateKey);
        if (translated) {
          countryKey = translated;
        }
      }

      if (listOfTimeSeries[j].length > i) {
        day[countryKey] = listOfTimeSeries[j][i][argumentKey];
      } else {
        day[countryKey] = undefined;
      }
    }

    list.push(day);
  }

  return list;
}

function computeChartDataFromMilestone(
  countryKeysList,
  origData,
  seriesData,
  seriesKey,
  milestoneKey,
  milestoneValue,
  period,
  translateKey,
) {
  const listOfTimeSeries = countryKeysList.map(key =>
    seriesData[key]
      .filter((val, i) => origData[key][i][milestoneKey] >= milestoneValue)
      .slice(0, period !== 0 ? period : undefined),
  );

  let arr = [];
  listOfTimeSeries.forEach((timeseries, countryIndex) => {
    timeseries.forEach((dayValues, dayIndex) => {
      if (!arr[dayIndex]) {
        arr[dayIndex] = { date: `D${dayIndex + 1}` };
      }
      let countryKey = countryKeysList[countryIndex];
      if (translateKey) {
        const translated = getTranslationFromKeyTo(countryKey, translateKey);
        if (translated) {
          countryKey = translated;
        }
      }
      arr[dayIndex][countryKey] = dayValues[seriesKey];
    });
  });

  return arr;
}

function getPeriodLabels(baseAnchorType, value) {
  if (value === 0) {
    return 'Todo';
  }

  const strBase = `${value} días`;
  return baseAnchorType === BASE_ANCHOR.fromEnd ? `Últ. ${strBase}` : strBase;
}

function transformData(
  baseData,
  { isRollingGrowth = true, isPerMillion = false },
) {
  let transformedData = isRollingGrowth
    ? addRollingGrowths(baseData)
    : addGrowths(baseData);
  if (isPerMillion) {
    return addPerMillion(transformedData);
  }
  return transformedData;
}

function ComparisonPage(props) {
  const classes = useStyles();

  const { data } = props;
  const [dataWithGrowths, setDataWithGrowths] = useState(
    addRollingGrowths(data),
  );

  const [selectedCountries, setSelectedCountries] = useState([
    'Spain',
    'Italy',
    'France',
  ]);
  const [isInputDisabled] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(30);
  const [selectedBaseAnchor, setSelectedBaseAnchor] = useState(
    BASE_ANCHOR.fromCase,
  );
  const [selectedBaseType, setSelectedBaseType] = useState(BASE_CASETYPE.death);
  const [selectedBaseValue, setSelectedBaseValue] = useState(30);
  const [selectedBaseInputValue, setSelectedBaseInputValue] = useState(
    selectedBaseValue,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isBaseValueFocused, setIsBaseValueFocused] = useState(false);
  const [isRollingGrowth, setIsRollingGrowth] = useState(true);
  const [isPerMillion, setIsPerMillion] = useState(false);

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  // TODO: Undo this hack
  const { setAppBarStyles } = props;
  useEffect(() => {
    if (isLarge) {
      setAppBarStyles({ marginRight: DRAWER_WIDTH });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLarge]);

  // TODO: Cache this
  function onGrowthTypeClick() {
    const config = { isRollingGrowth: !isRollingGrowth, isPerMillion };
    setDataWithGrowths(transformData(data, config));
    setIsRollingGrowth(!isRollingGrowth);
  }

  function onPerMillionClick() {
    const config = { isPerMillion: !isPerMillion, isRollingGrowth };
    setDataWithGrowths(transformData(data, config));
    setIsPerMillion(!isPerMillion);
  }

  const countryKeys = Object.keys(data).sort();

  // TODO: This is bad, we should only be recomputing on certain triggers
  // TODO: Change this to include recovered once fixed!
  let base = [
    'confirmed',
    'deaths',
    'newConfirmed',
    'newDeaths',
    'confirmedGrowth',
    'deathsGrowth',
    'mortalityRate',
  ];
  let chartData = [];
  if (selectedBaseAnchor === BASE_ANCHOR.fromEnd) {
    chartData = base.map(milestoneKey => {
      return computeChartDataFromEndDate(
        selectedCountries,
        dataWithGrowths,
        milestoneKey,
        selectedPeriod,
        'es',
      );
    });
  } else {
    chartData = base.map(milestoneKey => {
      return computeChartDataFromMilestone(
        selectedCountries,
        data,
        dataWithGrowths,
        milestoneKey,
        selectedBaseType,
        selectedBaseValue,
        selectedPeriod,
        'es',
      );
    });
  }
  console.log('chartData: ', chartData);

  // TODO: To avoid errors there should be a ['confirmed', 'deaths', 'recovered']
  // constant that we operate on
  const chartDataConfirmed = chartData[0];
  const chartDataDeaths = chartData[1];
  const chartDataNewConfirmed = chartData[2];
  const chartDataNewDeaths = chartData[3];
  const chartDataConfirmedGrowth = chartData[4];
  const chartDataDeathsGrowth = chartData[5];
  const mortalityRate = chartData[6];

  const onSelectedBaseValueConfirm = value => {
    let newValue = value;
    if (!value) {
      newValue = 30;
      setSelectedPeriod(30);
    }
    setSelectedBaseValue(newValue);
  };

  function onCountrySelectChange(event, newList, type) {
    const list = newList.map(item => item['en']);
    setSelectedCountries(list);
  }

  const [sortedTranslatedKeyList] = useState(
    getTranslationKeyList(data).sort((a, b) => a['es'] > b['es']),
  );

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, { keys: ['es'] });

  const translatedCountryKeys = getTranslationKeyListFromArray(
    selectedCountries,
    'es',
  ).map(country => country['es']);
  console.log('translatedCountryKeys', translatedCountryKeys);

  return (
    <div>
      <div className={clsx({ [classes.contentArea]: isLarge })}>
        <Container className={classes.container}>
          <Grid container className={classes.topContainer}>
            <Grid xs={6} md={12} item>
              {/*TODO: Añadir contexto en móvil (ej: viendo desde...)*/}
              <Typography variant="h4" className={classes.mainTitle}>
                Gráficas Comparativas
              </Typography>
            </Grid>
            <Hidden mdUp>
              <Zoom in>
                <Fab
                  size="large"
                  className={classes.fab}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <TuneSharpIcon />
                </Fab>
              </Zoom>
            </Hidden>
            <Grid item xs={12}>
              <Typography variant="caption">
                Fuentes: Universidad Johns Hopkins y elaboración propia
              </Typography>
              <br />
              <Typography variant="caption">
                Últ. actualización hace {props.lastUpdated}
              </Typography>
            </Grid>
          </Grid>
          <Divider
            className={isLarge ? classes.divider : classes.dividerSmall}
          />
          <Typography className={classes.chartTitle} variant="h5">
            Confirmados (total)
          </Typography>
          <Paper className={isLarge ? classes.paper : classes.paperMobile}>
            {chartDataConfirmed.length > 0 && (
              <ComparisonChart
                data={chartDataConfirmed}
                countryList={translatedCountryKeys}
                trimDate={false}
              />
            )}
          </Paper>
          <Typography className={classes.chartTitle} variant="h5">
            Confirmados (diarios)
          </Typography>
          <Paper className={isLarge ? classes.paper : classes.paperMobile}>
            {chartDataNewConfirmed.length > 0 && (
              <ComparisonChart
                data={chartDataNewConfirmed}
                countryList={translatedCountryKeys}
                trimDate={false}
              />
            )}
          </Paper>
          <Typography className={classes.chartTitle} variant="h5">
            Confirmados (crecimientos)
          </Typography>
          <Paper className={isLarge ? classes.paper : classes.paperMobile}>
            {chartDataConfirmedGrowth.length > 0 && (
              <ComparisonChart
                data={chartDataConfirmedGrowth}
                countryList={translatedCountryKeys}
                trimDate={false}
                isPercentage
              />
            )}
          </Paper>
          <Typography className={classes.chartTitle} variant="h5">
            Fallecidos (total)
          </Typography>
          <Paper className={classes.paper}>
            {chartDataDeaths.length > 0 && (
              <ComparisonChart
                data={chartDataDeaths}
                countryList={translatedCountryKeys}
              />
            )}
          </Paper>
          <Typography className={classes.chartTitle} variant="h5">
            Fallecidos (nuevos)
          </Typography>
          <Paper className={classes.paper}>
            {chartDataNewDeaths.length > 0 && (
              <ComparisonChart
                data={chartDataNewDeaths}
                countryList={translatedCountryKeys}
              />
            )}
          </Paper>
          <Typography className={classes.chartTitle} variant="h5">
            Fallecidos (crecimientos)
          </Typography>
          <Paper className={classes.paper}>
            {chartDataDeathsGrowth.length > 0 && (
              <ComparisonChart
                data={chartDataDeathsGrowth}
                countryList={translatedCountryKeys}
                isPercentage
              />
            )}
          </Paper>
          <Typography className={classes.chartTitle} variant="h5">
            Tasa de mortalidad
          </Typography>
          <Paper className={classes.paper}>
            {mortalityRate.length > 0 && (
              <ComparisonChart
                data={mortalityRate}
                countryList={translatedCountryKeys}
                isPercentage
              />
            )}
          </Paper>
        </Container>
      </div>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className={classes.drawer}
        variant={isLarge ? 'permanent' : 'temporary'}
        anchor="right"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.toolbar} />
        <div className={classes.controlGroup}>
          <Typography variant="h6" gutterBottom>
            Base Temporal
          </Typography>
          <Select
            id="base-select"
            value={selectedBaseAnchor}
            onChange={e => setSelectedBaseAnchor(e.target.value)}
            className={clsx(classes.secondaryControlGroup, classes.flexInput)}
          >
            <MenuItem value={BASE_ANCHOR.fromEnd}>Desde presente</MenuItem>
            <MenuItem value={BASE_ANCHOR.fromCase}>Desde caso...</MenuItem>
          </Select>
          {selectedBaseAnchor === BASE_ANCHOR.fromCase && (
            <div>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                wrap="nowrap"
              >
                <Grid item>
                  <Select
                    id="base-type-select"
                    value={selectedBaseType}
                    onChange={e => setSelectedBaseType(e.target.value)}
                    className={classes.baseTypeSelect}
                  >
                    <MenuItem value={BASE_CASETYPE.confirmed}>
                      Confirmado nº
                    </MenuItem>
                    <MenuItem value={BASE_CASETYPE.death}>
                      Fallecido nº
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <TextField
                    id="base-value-select"
                    // Not a phone, but hack to prompt number without stepper
                    type="tel"
                    value={selectedBaseInputValue}
                    onChange={e => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setSelectedBaseInputValue(e.target.value);
                      }
                    }}
                    onFocus={e => {
                      setIsBaseValueFocused(true);
                      e.target.select();
                    }}
                    onBlur={e => {
                      onSelectedBaseValueConfirm(e.target.value);
                      setIsBaseValueFocused(false);
                    }}
                    onKeyDown={e => {
                      e.keyCode === 13 &&
                        onSelectedBaseValueConfirm(selectedBaseInputValue);
                    }}
                  />
                </Grid>
              </Grid>
              {isLarge && isBaseValueFocused && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.baseValueInputInstructions}
                >
                  Confirma con "Enter" para recalcular
                </Typography>
              )}
            </div>
          )}
        </div>
        <div className={classes.controlGroup}>
          <Typography variant="h6" gutterBottom>
            Período
          </Typography>
          <Select
            id="period-select"
            value={selectedPeriod}
            className={classes.flexInput}
            onChange={e => setSelectedPeriod(e.target.value)}
          >
            {[0, 30, 14, 7].map(val => {
              return (
                <MenuItem value={val} key={val}>
                  {getPeriodLabels(selectedBaseAnchor, val)}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div className={classes.controlGroup}>
          <Typography variant="h6" className={classes.countrySelector}>
            Países
          </Typography>
          <Autocomplete
            debug
            size={isLarge ? 'medium' : 'small'}
            className={classes.formControl}
            multiple
            id="country-search-autocomplete"
            ListboxProps={!isLarge ? { style: { maxHeight: '25vh' } } : null}
            filterSelectedOptions
            filterOptions={filterOptions}
            options={sortedTranslatedKeyList}
            // options={countryKeys}
            getOptionLabel={option => option['es']}
            value={getTranslationKeyListFromArray(selectedCountries, 'es')}
            disabled={isInputDisabled}
            loading={isInputDisabled}
            onChange={onCountrySelectChange}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Elige países" />
            )}
          />
        </div>
        <div className={classes.controlGroup}>
          <Typography variant="h6" gutterBottom>
            Normalizar
          </Typography>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                label="Por millón de hab."
                control={
                  <Switch
                    checked={isPerMillion}
                    onChange={onPerMillionClick}
                    color="primary"
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </div>
        <div className={classes.controlGroup}>
          <Typography variant="h6" gutterBottom>
            Crecimiento
          </Typography>
          <ButtonGroup fullWidth className={classes.formControlSpecial}>
            <Button
              variant={isRollingGrowth ? 'contained' : 'outlined'}
              onClick={onGrowthTypeClick}
            >
              Móvil 5 días
            </Button>
            <Button
              variant={isRollingGrowth ? 'outlined' : 'contained'}
              onClick={onGrowthTypeClick}
            >
              24 horas
            </Button>
          </ButtonGroup>
        </div>
      </Drawer>
    </div>
  );
}

export default withLayout(ComparisonPage);
