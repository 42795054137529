import _ from 'lodash';
import numeral from '../utils/numeral';
import keyToIso from '../data/key-to-iso';
import countryData from '../data/country-data';

export const CAGR_PERIOD = 5;
export const FORMAT_INTEGER = '0,0';
export const FORMAT_DECIMAL = '0,0.0';

export function sortByIsoKey(list, isoKey) {
  return list.sort((a, b) => a[isoKey] > b[isoKey]);
}

export function getTranslationFromIsoKeyTo(isoKey, targetLanguage) {
  if (isoKey && countryData[isoKey] && countryData[isoKey].translations) {
    return countryData[isoKey].translations[targetLanguage];
  }
  return undefined;
}

export function getTranslationFromKeyTo(key, targetLanguage) {
  let isoKey = undefined;
  if (keyToIso[key]) {
    isoKey = keyToIso[key].code;
  }
  if (isoKey && countryData[isoKey] && countryData[isoKey].translations) {
    return countryData[isoKey].translations[targetLanguage];
  } else {
    return undefined;
  }
}

export function getTranslationKeyListFromArray(keyArray, targetLanguage) {
  return keyArray.map(key => {
    const translatedKey = getTranslationFromKeyTo(key, targetLanguage);
    return {
      en: key,
      es: translatedKey ? translatedKey : key,
    };
  });
}

export function getTranslationKeyList(originalDataWithKeys) {
  const originalKeys = Object.keys(originalDataWithKeys);
  return originalKeys.map(originalKey => {
    let isoKey = undefined;
    if (keyToIso[originalKey]) {
      isoKey = keyToIso[originalKey].code;
    }
    let translation = undefined;
    if (isoKey && countryData[isoKey] && countryData[isoKey].translations) {
      translation = countryData[isoKey].translations.es;
    }

    return {
      en: originalKey,
      es: translation ? translation : originalKey,
    };
  });
}

export function calculateGrowthFromKey(initialValueObj, endValueObj, key) {
  // Theoretically should return undefined
  // But graphs glitch out
  if (!initialValueObj || !initialValueObj[key]) {
    return null;
  }
  return _.round(
    ((endValueObj[key] - initialValueObj[key]) / initialValueObj[key]) * 100,
    1,
  );
}

function calculateGrowthRateFromKey(initialValueObj, endValueObj, key) {
  if (!initialValueObj || !initialValueObj[key]) {
    return null;
  }

  return calculateCAGR(endValueObj[key], initialValueObj[key], CAGR_PERIOD);
}

export function addGrowthsToTimeSeries(data) {
  return data.map((item, i, array) => {
    let newConfirmed = 0;
    let newDeaths = 0;
    // Theoretically, starting value should be undefined
    // However, some graphs glitch out
    let confirmedGrowth = null;
    let deathsGrowth = null;
    if (i !== 0) {
      const prevItem = array[i - 1];
      newConfirmed = item['confirmed'] - prevItem['confirmed'];
      newDeaths = item['deaths'] - prevItem['deaths'];
      confirmedGrowth = calculateGrowthFromKey(prevItem, item, 'confirmed');
      deathsGrowth = calculateGrowthFromKey(prevItem, item, 'deaths');
    }
    let mortalityRate = 0;
    if (item['confirmed'] !== 0) {
      mortalityRate = _.round((item['deaths'] / item['confirmed']) * 100, 2);
    }
    return {
      ...item,
      newConfirmed,
      newDeaths,
      confirmedGrowth,
      deathsGrowth,
      mortalityRate,
    };
  });
}

function addRollingGrowthToTimeSeries(data) {
  return data.map((item, i, array) => {
    let newConfirmed = 0;
    let newDeaths = 0;
    let confirmedGrowth = null;
    let deathsGrowth = null;
    if (i !== 0) {
      const prevItem = array[i - 1];
      newConfirmed = item['confirmed'] - prevItem['confirmed'];
      newDeaths = item['deaths'] - prevItem['deaths'];
    }
    if (i >= CAGR_PERIOD - 1) {
      const prevItem = array[i - CAGR_PERIOD + 1];
      confirmedGrowth = calculateGrowthRateFromKey(prevItem, item, 'confirmed');
      deathsGrowth = calculateGrowthRateFromKey(prevItem, item, 'deaths');
    }
    let mortalityRate = 0;
    if (item['confirmed'] !== 0) {
      mortalityRate = _.round((item['deaths'] / item['confirmed']) * 100, 2);
    }
    return {
      ...item,
      newConfirmed,
      newDeaths,
      confirmedGrowth,
      deathsGrowth,
      mortalityRate,
    };
  });
}

function addPerMillionToTimeSeries(data, countryKey) {
  // TODO: Work on this... Not great error handling
  if (!keyToIso[countryKey]) {
    return [];
  }
  const populationInMillions =
    countryData[keyToIso[countryKey].code].population / 1000000;
  return data.map((item, i, array) => ({
    ...item,
    confirmed: _.round(item.confirmed / populationInMillions, 0),
    deaths: _.round(item.deaths / populationInMillions, 0),
    newConfirmed: _.round(item.newConfirmed / populationInMillions, 0),
    newDeaths: _.round(item.newDeaths / populationInMillions, 0),
  }));
}

export function addPerMillion(data) {
  let retObj = {};
  Object.keys(data).forEach(countryKey => {
    retObj[countryKey] = addPerMillionToTimeSeries(
      data[countryKey],
      countryKey,
    );
  });
  return retObj;
}

export function addRollingGrowths(data) {
  let retObj = {};
  Object.keys(data).forEach(countryKey => {
    retObj[countryKey] = addRollingGrowthToTimeSeries(data[countryKey]);
  });
  return retObj;
}

export function addGrowths(data) {
  let retObj = {};
  Object.keys(data).forEach(countryKey => {
    retObj[countryKey] = addGrowthsToTimeSeries(data[countryKey]);
  });
  return retObj;
}

export function calculateGlobalSummary(series) {
  const last = series.length - 1;
  const last24Confirmed =
    series[last]['confirmed'] - series[last - 1]['confirmed'];
  const confirmedTotal = series[last]['confirmed'];
  const last24Deaths = series[last]['deaths'] - series[last - 1]['deaths'];
  const deathsTotal = series[last]['deaths'];
  const mortalityRate =
    (series[last]['deaths'] / series[last]['confirmed']) * 100;

  return [
    {
      title: 'Confirmados - totales',
      value: confirmedTotal,
      displayValue: numeral(confirmedTotal).format(FORMAT_INTEGER),
    },
    {
      title: 'Confirmados - últ. 24h',
      value: last24Confirmed,
      displayValue: numeral(last24Confirmed).format(FORMAT_INTEGER),
    },
    {
      title: 'Fallecidos - totales',
      value: deathsTotal,
      displayValue: numeral(deathsTotal).format(FORMAT_INTEGER),
    },
    {
      title: 'Fallecidos - últ. 24h',
      value: last24Deaths,
      displayValue: numeral(last24Deaths).format(FORMAT_INTEGER),
    },
    {
      title: 'Tasa mortalidad',
      value: mortalityRate,
      displayValue: `${numeral(mortalityRate).format(FORMAT_DECIMAL)}%`,
    },
  ];
}

function calculateCAGR(endVal, initVal, period) {
  return _.round((Math.pow(endVal / initVal, 1 / period) - 1) * 100, 1);
}

function calculateGrowth(initValue, endValue) {
  return _.round(((endValue - initValue) / initValue) * 100, 1);
}

function calculateDoublingDays(series, key) {
  const len = series.length;
  const last = series[len - 1][key];
  for (let i = 1; i < len - 1; i++) {
    if (last >= series[len - 1 - i][key] * 2) {
      return i;
    }
  }
  return undefined;
}

export function calculateSeriesSummaryByKey(series, key) {
  const len = series.length;
  const thisCAGR = calculateCAGR(
    series[len - 1][key],
    series[len - 1 - CAGR_PERIOD][key],
    CAGR_PERIOD,
  );
  const prevCAGR = calculateCAGR(
    series[len - 1 - CAGR_PERIOD][key],
    series[len - 1 - CAGR_PERIOD * 2][key],
    CAGR_PERIOD,
  );
  const doublingDays = calculateDoublingDays(series, key);
  const growth = calculateGrowth(series[len - 2][key], series[len - 1][key]);
  const last24 = series[len - 1][key] - series[len - 2][key];
  const total = series[len - 1][key];

  return [
    {
      name: `total-${key}`,
      title: 'Total',
      value: total,
      displayValue: numeral(total).format(FORMAT_INTEGER),
    },
    {
      name: `last24-${key}`,
      title: 'Últimas 24 horas',
      shortTitle: 'Últ. 24h',
      value: last24,
      displayValue: numeral(last24).format(FORMAT_INTEGER),
    },
    {
      name: `doublingTime-${key}`,
      title: 'Tiempo en el que se han doblado',
      shortTitle: 'Doblado en...',
      value: doublingDays,
      displayValue: `${doublingDays} días`,
      tooltip: `Hace ${doublingDays} días, el número de casos era la mitad. Cuanto más alto este número, mejor.`,
    },
    {
      name: `growth-${key}`,
      title: 'Crecimiento últ. 24 horas',
      shortTitle: 'Crec. 24h',
      value: growth,
      displayValue: `${numeral(growth).format(FORMAT_DECIMAL)}%`,
    },
    {
      name: `cagrLastX-${key}`,
      title: `Crecimiento medio últ. ${CAGR_PERIOD} días`,
      shortTitle: `CAGR últ. ${CAGR_PERIOD}d`,
      value: thisCAGR,
      displayValue: `${numeral(thisCAGR).format(FORMAT_DECIMAL)}%`,
      tooltip: `Crecimiento compuesto medio de los últimos ${CAGR_PERIOD} días`,
    },
    {
      name: `cagrPrev-${key}`,
      title: `Crecimiento medio ant. ${CAGR_PERIOD} días`,
      shortTitle: `CAGR ant. ${CAGR_PERIOD}d`,
      value: prevCAGR,
      displayValue: `${numeral(prevCAGR).format(FORMAT_DECIMAL)}%`,
      tooltip: `Crecimiento compuesto medio en los ${CAGR_PERIOD} días anteriores`,
    },
  ];
}
