import React from 'react';
import {
  EventTracker,
  HoverState,
  ValueScale,
} from '@devexpress/dx-react-chart';
import {
  Chart,
  BarSeries,
  ValueAxis,
  Tooltip,
  SplineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import ArgumentAxis from './ArgumentAxis';

const PercentageTooltip = (props, data) => {
  const date = data[props.targetItem.point].date;

  // TODO: Remove
  let { text } = props;
  if (props.targetItem.series === 'Crecimiento Casos Diarios') {
    const symbol = Number.parseFloat(text) > 0 ? '+' : '';
    text = `${symbol}${text}%`;
  }

  return (
    <>
      <Tooltip.Content {...props} text={date} align="center" />
      <Tooltip.Content {...props} text={text} align="center" />
    </>
  );
};

export default function BarChart(props) {
  const { data, valueField, argumentField, chartType } = props;

  let chart;
  if (chartType && chartType === 'spline') {
    chart = (
      <SplineSeries
        name="Crecimiento Casos Diarios"
        valueField={valueField}
        argumentField={argumentField}
        scaleName={valueField}
      />
    );
  } else {
    chart = (
      <BarSeries
        name="Casos Confirmados Acumulados"
        valueField={valueField}
        argumentField={argumentField}
        scaleName={valueField}
      />
    );
  }

  return (
    <Chart data={data} height={300}>
      <ValueScale name={valueField} />
      <ArgumentAxis />
      <ValueAxis scaleName={valueField} />
      {chart}
      <EventTracker />
      <HoverState />
      <Tooltip contentComponent={props => PercentageTooltip(props, data)} />
    </Chart>
  );
}
