import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import withLayout from './withLayout';
import Link from '@material-ui/core/Link';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => {
  return {
    container: {
      maxWidth: 950,
      margin: theme.spacing(4, 2),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(4, 8),
      },
    },
    mainTitle: {
      marginBottom: theme.spacing(3),
    },
    divider: {
      marginBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3, 3),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 6),
      },
    },
    sectionHeadline: {
      marginBottom: theme.spacing(2),
    },
    section: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(6),
      },
    },
    sectionDivider: {
      maxWidth: '33%',
      marginBottom: theme.spacing(2),
    },
  };
});

function Section(props) {
  const classes = useStyles();
  return <div className={classes.section}>{props.children}</div>;
}

function SubHeadline(props) {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.sectionHeadline}>
      {props.children}
    </Typography>
  );
}

function P(props) {
  return (
    <Typography variant="body1" paragraph>
      {props.children}
    </Typography>
  );
}

function AnimationPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.container}>
      {/*<Container maxWidth="md">*/}
      <Typography variant="h4" gutterBottom>
        Animaciones
      </Typography>
      <Typography variant="body1" className={classes.mainTitle}>
        Crédito a Our World in Data, 3Blue1Brown, Minute Phyisics.
      </Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Section>
          <iframe
            src="https://ourworldindata.org/grapher/new-covid-deaths-per-million"
            style={{ width: '100%', height: '550px', border: '0px none' }}
          />
        </Section>
        <Divider />
        <Section>
          <iframe
            src="https://ourworldindata.org/grapher/daily-covid-deaths-3-day-average"
            style={{ width: '100%', height: '550px', border: '0px none' }}
          />
        </Section>
        <Section>
          <iframe
            src="https://ourworldindata.org/grapher/covid-confirmed-deaths-since-5th-death"
            style={{ width: '100%', height: '550px', border: '0px none' }}
          />
        </Section>
        <Section>
          <iframe
            src="https://ourworldindata.org/grapher/new-covid-cases-per-million"
            style={{ width: '100%', height: '550px', border: '0px none' }}
          />
        </Section>
        <Section>
          <iframe
            src="https://ourworldindata.org/grapher/covid-confirmed-cases-since-100th-case"
            style={{ width: '100%', height: '550px', border: '0px none' }}
          />
        </Section>
        <Section>
          <iframe
            src="https://ourworldindata.org/grapher/share-of-the-population-that-is-70-years-and-older"
            style={{ width: '100%', height: '550px', border: '0px none' }}
          />
        </Section>
        <Section>
          <iframe
            width="100%"
            height={isLarge ? '550' : '275'}
            src="https://www.youtube-nocookie.com/embed/Kas0tIxDvrg"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Section>
        <Section>
          <iframe
            width="100%"
            height={isLarge ? '550' : '275'}
            src="https://www.youtube-nocookie.com/embed/54XLXg4fYsc"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Section>
      </Paper>
    </div>
  );
}

export default withLayout(AnimationPage);
