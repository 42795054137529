import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import withLayout from './withLayout';
import SimpleTable from './SimpleTable';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SupplementTable from './SupplementTable';
import numeral from '../utils/numeral';
import { FORMAT_INTEGER, FORMAT_DECIMAL } from '../utils/dataUtils';
import LastMinuteChart from './LastMinuteChart';
import makeStyles from '@material-ui/core/styles/makeStyles';

const LAST_MINUTE_DATA_URL = 'https://corona.lmao.ninja/v2/countries';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
  dataCaption: {
    marginBottom: theme.spacing(3),
    display: 'block',
  },
}));

function mapWorldObjectToValues(worldObject) {
  const { cases, todayCases, deaths, todayDeaths, recovered } = worldObject;
  const mortality = (deaths / cases) * 100;

  return [
    {
      title: 'Confirmados - total',
      displayValue: numeral(cases).format(FORMAT_INTEGER),
    },
    {
      title: 'Confirmados - hoy',
      value: todayCases,
      displayValue: numeral(todayCases).format(FORMAT_INTEGER),
    },
    {
      title: 'Fallecidos - total',
      value: deaths,
      displayValue: numeral(deaths).format(FORMAT_INTEGER),
    },
    {
      title: 'Fallecidos - hoy',
      value: todayDeaths,
      displayValue: numeral(todayDeaths).format(FORMAT_INTEGER),
    },
    {
      title: 'Recuperados - total',
      value: recovered,
      displayValue: numeral(recovered).format(FORMAT_INTEGER),
    },
    {
      title: 'Tasa de mortalidad',
      value: mortality,
      displayValue: `${numeral(mortality).format(FORMAT_DECIMAL)}%`,
    },
  ];
}

function addIfNumber(dst, src) {
  return _.isNumber(dst) ? dst + src : dst;
}

function calculateWorldTotals(data) {
  return data.reduce(
    (ac, countryObject) => {
      return _.mergeWith({}, ac, countryObject, addIfNumber);
    },
    { country: 'World' },
  );
}

function LastMinutePage(props) {
  const classes = useStyles();
  const { data, series } = props;

  if (_.isEmpty(data) || _.isEmpty(series)) {
    return null;
  }

  const worldRemoved = data.filter(item => item.country !== 'World');
  const worldTotals = mapWorldObjectToValues(
    calculateWorldTotals(worldRemoved),
  );
  const worldSeries = props.series['World'];

  return (
    <div>
      <Container>
        <Typography variant="h4" as="h2" className={classes.mainTitle}>
          Última hora
        </Typography>
        <Typography variant="caption" className={classes.dataCaption}>
          Fuentes: Universidad Johns Hopkins (gráficas) y Worldometers.info
          (última hora)
        </Typography>
        <Divider className={classes.divider} />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item container xs={12} md={4} spacing={2}>
              <SupplementTable data={worldTotals} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h5">Confirmados</Typography>
              {worldSeries && (
                <LastMinuteChart
                  data={worldSeries}
                  valueField="confirmed"
                  argumentField="date"
                  color="#ffc107"
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h5">Fallecidos</Typography>
              {worldSeries && (
                <LastMinuteChart
                  data={worldSeries}
                  valueField="deaths"
                  argumentField="date"
                  color="#f44336"
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
      {props.data.length > 0 && <SimpleTable {...props} data={worldRemoved} />}
    </div>
  );
}

function LastMinutePageWithLayout(props) {
  return withLayout(LastMinutePage)(props);
}

export default function LatsMinutePageWithData(props) {
  // const [series] = useState(props.data);
  const [lastMinuteData, setLastMinuteData] = useState(undefined);
  useEffect(() => {
    axios
      .get(LAST_MINUTE_DATA_URL)
      .then(res => {
        console.log('LastMinutePageWithData data received: ', res.data);
        setLastMinuteData(res.data);
      })
      .catch(err => console.log('error', err));
  }, []);

  return <LastMinutePageWithLayout data={lastMinuteData} series={props.data} />;
}
