import React from 'react';
import {
  EventTracker,
  HoverState,
  ValueScale,
} from '@devexpress/dx-react-chart';
import {
  Chart,
  ValueAxis,
  Tooltip,
  SplineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import ArgumentAxis from './ArgumentAxis';

const PercentageTooltip = (props, data) => {
  const date = data[props.targetItem.point].date;

  let { text } = props;
  text = `${text}%`;

  return (
    <>
      <Tooltip.Content {...props} text={date} align="center" />
      <Tooltip.Content {...props} text={text} align="center" />
    </>
  );
};

function modifyDomain(domain) {
  return [0, domain[domain.length - 1]];
}

export default function GrowthChart(props) {
  const { data, valueField, argumentField } = props;

  return (
    <Chart data={data} height={300}>
      <ValueScale name={valueField} modifyDomain={modifyDomain} />
      <ArgumentAxis />
      <ValueAxis scaleName={valueField} />
      <SplineSeries
        name={valueField}
        valueField={valueField}
        argumentField={argumentField}
        scaleName={valueField}
      />
      <EventTracker />
      <HoverState />
      <Tooltip contentComponent={props => PercentageTooltip(props, data)} />
    </Chart>
  );
}
