import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SupplementTable from './SupplementTable';
import Typography from '@material-ui/core/Typography';
import BarChart from './BarChart';

const useStyles = makeStyles(theme => ({
  subHeadline: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ThreeSplitView(props) {
  const classes = useStyles();

  const {
    summaryData,
    chartData,
    leftTitle,
    rightTitle,
    leftValueField,
    rightValueField,
    showArgumentAxis,
    chartType,
  } = props;

  return (
    <>
      <Grid container item xs={12} md={4} spacing={1}>
        {summaryData && <SupplementTable data={summaryData} />}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="h6" className={classes.subHeadline}>
          {leftTitle}
        </Typography>
        <BarChart
          data={chartData}
          valueField={leftValueField}
          argumentField="date"
          showArgumentAxis={showArgumentAxis}
          chartType={chartType}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="h6" className={classes.subHeadline}>
          {rightTitle}
        </Typography>
        <BarChart
          data={chartData}
          valueField={rightValueField}
          argumentField="date"
          showArgumentAxis={showArgumentAxis}
          chartType={chartType}
        />
      </Grid>
    </>
  );
}
