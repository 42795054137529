import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(1),
    // borderRadius: 0,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    fontSize: '0.75rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.85rem',
    },
  },
  cardData: {
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem',
    },
  },
}));

export default function SupplementTable(props) {
  const classes = useStyles();

  const { data } = props;

  return data.map((item, i) => (
    <Grid item xs={6} sm={3} md={6} key={item.title}>
      <Card variant="outlined" className={classes.card} square>
        <Typography
          className={classes.cardTitle}
          variant="body2"
          color="textSecondary"
          gutterBottom
        >
          {item.title}
        </Typography>
        <Divider className={classes.divider} />
        <Typography
          className={classes.cardData}
          variant="h5"
          color="textPrimary"
          align="right"
        >
          {item.displayValue || item.value}
        </Typography>
      </Card>
    </Grid>
  ));
}
