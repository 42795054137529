import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import { WhatsappShareButton, EmailShareButton } from 'react-share';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import FacebookShareButton from 'react-share/es/FacebookShareButton';
import TwitterShareButton from 'react-share/es/TwitterShareButton';
import LinkedinShareButton from 'react-share/es/LinkedinShareButton';
import firebase from '../utils/firebase';
import 'firebase/analytics';

const useStyles = makeStyles(theme => {
  return {
    shareButtonRoot: {
      width: '100%',
    },
    popoverGrid: {
      width: '90vw',
      maxWidth: 330,
      padding: theme.spacing(3),
      maxHeight: '60vh',
      color: 'black',
      backgroundColor: green['A100'],
      '& .MuiButton-root': {
        width: '100%',
        backgroundColor: green['A100'],
        color: theme.palette.getContrastText(green['A100']),
        borderColor: theme.palette.getContrastText(green['A100']),
        margin: '0 auto',
        '&:hover': {
          backgroundColor: theme.palette.getContrastText(green['A100']),
          color: green['A100'],
        },
      },
    },
  };
});

const BASE_URL = 'https://coviz19.org';

function getPathInfo(path) {
  switch (path) {
    case '/compare':
      return {
        url: BASE_URL + path,
        title: 'CoViz19 - Comparador de países',
      };

    case '/detail':
      return {
        url: BASE_URL + path,
        title: 'CoViz19 - Visualización de datos por país',
      };

    case '/about':
    case '/':
    default:
      return {
        url: BASE_URL,
        title: 'CoViz19 - Visualización de datos de Covid-19',
      };
  }
}

export default function SocialShareGrid(props) {
  const classes = useStyles();
  const location = useLocation();

  const { url, title } = getPathInfo(location.pathname);

  // This will fail silently, review
  // I think it might also not register events as it might interrupt code exec
  function onClickShare(method) {
    function logEvent(method) {
      firebase.analytics().logEvent('share', {
        method,
      });
    }

    return new Promise((resolve, reject) => {
      logEvent(method);
      resolve();
    });
  }

  return (
    <Grid
      container
      className={classes.popoverGrid}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-evenly"
        spacing={2}
      >
        <Grid item xs={6} align="center">
          <WhatsappShareButton
            className={classes.shareButtonRoot}
            url={url}
            title={title}
            separator={'\n'}
            beforeOnClick={() => onClickShare('whatsapp')}
          >
            <Button
              variant="outlined"
              startIcon={<WhatsAppIcon />}
              component="div"
            >
              WhatsApp
            </Button>
          </WhatsappShareButton>
        </Grid>
        <Grid item xs={6} align="center">
          <EmailShareButton
            className={classes.shareButtonRoot}
            url={url}
            subject={title}
            body={title}
            separator={'\n'}
            beforeOnClick={() => onClickShare('email')}
          >
            <Button
              variant="outlined"
              startIcon={<MailOutlineIcon />}
              component="div"
            >
              Email
            </Button>
          </EmailShareButton>
        </Grid>
        <Grid item xs={6} align="center">
          <FacebookShareButton
            className={classes.shareButtonRoot}
            url={url}
            quote={title}
            beforeOnClick={() => onClickShare('facebook')}
          >
            <Button
              variant="outlined"
              startIcon={<FacebookIcon />}
              component="div"
            >
              Facebook
            </Button>
          </FacebookShareButton>
        </Grid>
        <Grid item xs={6} align="center">
          <TwitterShareButton
            className={classes.shareButtonRoot}
            url={url}
            title={title}
            beforeOnClick={() => onClickShare('twitter')}
          >
            <Button
              variant="outlined"
              startIcon={<TwitterIcon />}
              component="div"
            >
              Twitter
            </Button>
          </TwitterShareButton>
        </Grid>
        <Grid item xs={6} align="center">
          <LinkedinShareButton
            className={classes.shareButtonRoot}
            url={url}
            title={title}
            source={url}
            beforeOnClick={() => onClickShare('linkedin')}
          >
            <Button
              variant="outlined"
              startIcon={<LinkedInIcon />}
              component="div"
            >
              LinkedIn
            </Button>
          </LinkedinShareButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
