import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  createMuiTheme,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import numeral from '../utils/numeral';
import {
  getTranslationFromIsoKeyTo,
  getTranslationFromKeyTo,
} from '../utils/dataUtils';

const useStyles = makeStyles(theme => {
  return {
    tableContainer: {
      // height: 'calc(100vh - 90px)',
      width: '100%',
    },
  };
});

function alignRight() {
  return {
    style: {
      textAlign: 'right',
    },
    children: [],
  };
}

const FORMAT_INTEGER = '0,0';

const columns = [
  {
    name: 'country',
    label: 'País',
  },
  {
    name: 'cases',
    label: 'Casos',
    options: {
      sortDirection: 'desc',
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'todayCases',
    label: 'Casos hoy',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'deaths',
    label: 'Fallecidos',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'todayDeaths',
    label: 'Fallecidos hoy',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'recovered',
    label: 'Recuperados',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'active',
    label: 'Activos',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'critical',
    label: 'Críticos',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'casesPerOneMillion',
    label: 'Casos / 1M',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
  {
    name: 'deathsPerOneMillion',
    label: 'Fallecidos / 1M',
    options: {
      setCellProps: () => alignRight(),
      customBodyRender: value => numeral(value).format(FORMAT_INTEGER),
    },
  },
];

const getTableTheme = (mainTheme, isLarge) =>
  createMuiTheme({
    ...mainTheme,
    overrides: {
      MuiTableCell: {
        head: {
          zIndex: '8000 !important',
          height: 48,
          padding: '8px 8px 8px',
        },
      },
      // This is the only way I've found for the table to behave responsively
      // Updating options.responsive wouldn't trigger re-render
      // 60px is the small drawer width
      // TODO: Make it respond to drawer open
      MuiPaper: {
        root: {
          width: isLarge ? 'calc(100% - 60px)' : 'unset',
          // margin: mainTheme.spacing(0, 2),
          backgroundColor: mainTheme.palette.table,
          // height: '100%',
        },
      },
    },
  });

function translateTable(data, languageKey) {
  return data.map(row => {
    if (!row.countryInfo || !row.countryInfo.iso2) {
      return row;
    }
    const isoKey = row.countryInfo.iso2;
    const translation = getTranslationFromIsoKeyTo(isoKey, languageKey);
    if (!translation) {
      return row;
    } else {
      return {
        ...row,
        country: translation,
      };
    }
  });
}

export default function LastMinuteTable(props) {
  const mainTheme = useTheme();
  const classes = useStyles();
  const [rows] = useState(translateTable(props.data, 'es'));
  const isLarge = useMediaQuery(mainTheme.breakpoints.up('md'));

  return (
    <Paper className={classes.tableContainer}>
      <MuiThemeProvider theme={getTableTheme(mainTheme, isLarge)}>
        <MUIDataTable
          data={rows}
          columns={columns}
          options={{
            responsive: 'scrollFullHeightFullWidth',
            fixedHeaderOptions: {
              xAxis: true,
              yAxis: true,
            },
            pagination: false,
            print: false,
            download: false,
            search: false,
            filter: false,
            viewColumns: false,
            selectableRows: 'none',
            selectableRowsHeader: false,
          }}
        />
      </MuiThemeProvider>
    </Paper>
  );
}
