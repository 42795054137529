import React from 'react';
import {
  ArgumentScale,
  EventTracker,
  HoverState,
  ValueScale,
  SplineSeries,
} from '@devexpress/dx-react-chart';
import {
  Chart,
  ValueAxis,
  Legend,
  Tooltip,
  // SplineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import ArgumentAxis from './ArgumentAxis';

const legendRootStyle = {
  display: 'flex',
  margin: 'auto auto 0',
  overflowX: 'hidden',
  paddingBottom: 0,
  flexDirection: 'row',
};
const LegendRoot = props => <Legend.Root {...props} style={legendRootStyle} />;

const LegendItem = props => {
  const legendItemStyle = {
    minWidth: 0,
    flexDirection: 'column',
    marginLeft: '-2px',
    marginRight: '-2px',
  };
  return <Legend.Item {...props} style={legendItemStyle} />;
};

const LegendLabel = props => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const legendLabelStyle = {
    fontSize: isSmall ? '0.75rem' : '0.9rem',
    marginTop: theme.spacing(1),
  };
  return (
    <Typography variant="body1" style={legendLabelStyle} {...props}>
      {props.text}
    </Typography>
  );
};

const PercentageTooltip = (props, data, isPercentage) => {
  const date = data[props.targetItem.point].date;
  let { text } = props;
  if (isPercentage) {
    text = `${text}%`;
  }
  return (
    <>
      <Tooltip.Content {...props} text={date} align="center" />
      <Tooltip.Content {...props} text={text} align="center" />
    </>
  );
};

const CustomSeries = props => {
  const { coordinates } = props;
  const newCoordinates = coordinates.filter(item => item.value !== null);
  return <SplineSeries.Path {...props} coordinates={newCoordinates} />;
};

export default function ComparisonChart(props) {
  const { data, countryList, isPercentage } = props;

  const scaleName = 'persons';

  return (
    <Chart data={data} height={400}>
      <Legend
        position="bottom"
        rootComponent={LegendRoot}
        itemComponent={LegendItem}
        labelComponent={LegendLabel}
      />
      <ArgumentAxis trimDate={false} />

      <ValueAxis scaleName={scaleName} />
      <ArgumentScale name="date" />
      <ValueScale name={scaleName} />
      {countryList.map(countryName => {
        // console.log('countryName: ', countryName);
        return (
          <SplineSeries
            key={countryName}
            name={countryName}
            valueField={countryName}
            argumentField="date"
            scaleName={scaleName}
            seriesComponent={CustomSeries}
          />
        );
      })}
      <EventTracker />
      <HoverState />
      <Tooltip
        contentComponent={props => PercentageTooltip(props, data, isPercentage)}
      />
    </Chart>
  );
}
