import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuSharpIcon from '@material-ui/icons/MenuSharp';
import TimelineSharpIcon from '@material-ui/icons/TimelineSharp';
import TableChartSharpIcon from '@material-ui/icons/TableChartSharp';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import CompareArrowsSharpIcon from '@material-ui/icons/CompareArrowsSharp';
import ChevronLeftSharpIcon from '@material-ui/icons/ChevronLeftSharp';
import AlarmSharpIcon from '@material-ui/icons/AlarmSharp';
import OndemandVideoSharpIcon from '@material-ui/icons/OndemandVideoSharp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Hidden from '@material-ui/core/Hidden';
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import { ReactComponent as Logo } from '../media/logo_jl.svg';
import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Popover from '@material-ui/core/Popover';
import SocialShareGrid from './SocialShareGrid';

const MENU_WIDTH_SM = 60;
const MENU_WIDTH_LG = 240;

const useStyles = makeStyles(theme => {
  const transitionOut = theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  });
  const transitionIn = theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });

  return {
    appBar: {
      // zIndex: theme.zIndex.drawer + 1,
      marginBottom: theme.spacing(3),
    },
    appBarOpen: {
      width: `calc(100% - ${MENU_WIDTH_LG}px)`,
      transition: transitionIn,
    },
    appBarClosed: {
      marginLeft: MENU_WIDTH_SM,
      width: `calc(100% - ${MENU_WIDTH_SM}px)`,
    },
    transitionOut: {
      transition: transitionOut,
    },
    removeElevation: {
      boxShadow: 'none',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      // necessary for content to be below app bar
      // TODO: https://github.com/mui-org/material-ui/issues/10076#issuecomment-361232810
      minHeight: 48,
    },
    toolbarOpen: {
      justifyContent: 'flex-end',
    },
    toolbarClosed: {
      justifyContent: 'center',
    },
    marginOpened: {
      marginLeft: MENU_WIDTH_LG,
    },
    marginClosed: {
      marginLeft: MENU_WIDTH_SM,
    },
    widthCollapsed: {
      width: MENU_WIDTH_SM,
    },
    widthOpen: {
      width: MENU_WIDTH_LG,
    },
    drawerClosed: {
      overflowX: 'hidden',
      width: MENU_WIDTH_SM,
      transition: transitionOut,
    },
    drawerOpen: {
      width: MENU_WIDTH_LG,
      transition: transitionIn,
    },
    contentOpen: {
      transition: transitionIn,
    },
    mobileHamburger: {
      marginRight: theme.spacing(1),
    },
    logo: {
      height: '28px',
      marginRight: theme.spacing(2),
    },
    menuListItem: {
      whiteSpace: 'nowrap',
    },
    shareButton: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
      backgroundColor: green['A200'],
      '&:hover': {
        backgroundColor: '#1ae883',
      },
    },
    popoverPaper: {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      '& .MuiPopover-paper': {
        marginTop: theme.spacing(2),
      },
    },
  };
});

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function withLayout(Component) {
  const WithLayoutComponent = props => {
    const { data } = props;

    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleShareButtonClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const isSharePopoverOpen = Boolean(anchorEl);

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const hasChanged = isOpen !== usePrevious(isOpen);

    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

    const handleMenuClick = () => setIsOpen(!isOpen);

    // TODO: Hack to adjust app bar, improve
    const [appBarStyles, setAppBarStyles] = useState({});

    return (
      <>
        <HideOnScroll>
          <AppBar
            className={clsx(classes.appBar, classes.removeElevation, {
              // TODO: This is a nightmare and all over the code
              [classes.marginOpened]: isLarge && isOpen,
              [classes.marginClosed]: isLarge && !isOpen,
              [classes.appBarOpen]: isLarge && isOpen,
              [classes.appBarClosed]: isLarge && !isOpen,
              [classes.transitionOut]: isLarge && !isOpen && hasChanged,
            })}
            position="sticky"
            color="secondary"
          >
            <Toolbar variant="dense" style={appBarStyles}>
              <Hidden smUp implementation="css">
                <IconButton onClick={handleMenuClick}>
                  <MenuSharpIcon className={classes.mobileHamburger} />
                </IconButton>
              </Hidden>
              <Logo className={classes.logo} />
              <Hidden smDown implementation="css">
                <Typography variant="body1">
                  Visualización de datos de Covid19
                </Typography>
              </Hidden>
              <Button
                className={classes.shareButton}
                endIcon={<ShareSharpIcon />}
                variant="contained"
                color="primary"
                size="small"
                onClick={handleShareButtonClick}
              >
                Comparte
              </Button>
              <Popover
                className={classes.popoverPaper}
                open={isSharePopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                // placement="bottom-end"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <SocialShareGrid />
              </Popover>
            </Toolbar>
            {!data && <LinearProgress />}
          </AppBar>
        </HideOnScroll>
        <Drawer
          open={isOpen}
          onClose={handleMenuClick}
          variant={isLarge ? 'permanent' : 'temporary'}
          className={clsx({
            [classes.drawerOpen]: isLarge && isOpen,
            [classes.drawerClosed]: isLarge && !isOpen,
            [classes.widthOpen]: isOpen,
          })}
          classes={{
            paper: clsx(classes.removeElevation, {
              [classes.drawerOpen]: isLarge && isOpen,
              [classes.drawerClosed]: isLarge && !isOpen,
              [classes.widthOpen]: isOpen,
            }),
          }}
        >
          <div
            className={clsx(classes.toolbar, {
              [classes.toolbarOpen]: isLarge && isOpen,
              [classes.toolbarClosed]: isLarge && !isOpen,
            })}
          >
            <IconButton onClick={handleMenuClick}>
              {isOpen ? <ChevronLeftSharpIcon /> : <MenuSharpIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              className={classes.menuListItem}
              button
              key="Last Minute"
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <AlarmSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Última hora" />
            </ListItem>
            <ListItem
              className={classes.menuListItem}
              button
              key="Series"
              component={Link}
              to="/detail"
            >
              <ListItemIcon>
                <TimelineSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Detalle país" />
            </ListItem>
            <ListItem
              className={classes.menuListItem}
              button
              key="Comparativa"
              component={Link}
              to="/compare"
            >
              <ListItemIcon>
                <CompareArrowsSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Comparativa" />
            </ListItem>
            <ListItem
              className={classes.menuListItem}
              button
              key="Animations"
              component={Link}
              to="/animations"
            >
              <ListItemIcon>
                <OndemandVideoSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Animaciones" />
            </ListItem>
            <ListItem
              className={classes.menuListItem}
              button
              key="About"
              component={Link}
              to="/about"
            >
              <ListItemIcon>
                <InfoSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Acerca de" />
            </ListItem>
          </List>
        </Drawer>
        <div
          className={clsx({
            [classes.marginOpened]: isLarge && isOpen,
            [classes.marginClosed]: isLarge && !isOpen,
            [classes.contentOpen]: isLarge && isOpen,
            [classes.transitionOut]: isLarge && !isOpen && hasChanged,
          })}
        >
          {// TODO: Terrible way of passing down data, fix
          data && <Component {...props} setAppBarStyles={setAppBarStyles} />}
        </div>
      </>
    );
  };

  return WithLayoutComponent;
}
