import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withLayout from './withLayout';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import { AutoSizer } from 'react-virtualized';
import {
  Grid as DataGrid,
  VirtualTable,
  TableBandHeader,
  TableHeaderRow,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import { calculateSeriesSummaryByKey } from '../utils/dataUtils';
import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';

const useStyles = makeStyles(theme => {
  return {
    container: {
      paddingTop: theme.spacing(3),
    },
    mainTitle: {
      marginBottom: theme.spacing(1),
    },
    topContainer: {
      marginBottom: theme.spacing(3),
    },
    divider: {
      marginBottom: theme.spacing(6),
    },
    dividerSmall: {
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      height: 'calc(100vh - 80px)',
    },
  };
});

function explodeKeys(summaryData) {
  return summaryData.reduce((ac, val) => {
    return { ...ac, [val.name]: val };
  }, {});
}

function getRows(data) {
  if (!data) {
    return null;
  }
  const countryKeys = Object.keys(data);
  // const last = data[countryKeys[0]].length - 1;

  return countryKeys.map(key => ({
    country: { name: 'country', value: key, title: 'País' },
    // country: key,
    ...explodeKeys(calculateSeriesSummaryByKey(data[key], 'confirmed')),
    ...explodeKeys(calculateSeriesSummaryByKey(data[key], 'deaths')),
    // ...explodeKeys(calculateSeriesSummaryByKey(data[key], 'recovered')),
  }));
}

function extractColumnsFromSampleRow(row) {
  const columnKeys = Object.keys(row);
  return columnKeys.map(columnKey => {
    return {
      name: row[columnKey].name,
      title: row[columnKey].shortTitle || row[columnKey].title,
      getCellValue: row => row[columnKey].displayValue || row[columnKey].value,
    };
  });
}

function extendColumnExtensionsWithComparison(columnExtensions) {
  return columnExtensions.map(colExtension => ({
    ...colExtension,
    compare: (a, b) => {
      let aNum = parseFloat(a.replace(/[^\d,-]/g, ''));
      if (!isNaN(aNum)) {
        a = aNum;
        b = b.replace(/[^\d,-]/g, '');
      }

      if (a === b) {
        return 0;
      }
      return a < b ? -1 : 1;
    },
  }));
}

function getColumnExtensionsFromSampleRow(row, isLarge, width) {
  const columnKeys = Object.keys(row);

  return columnKeys.map((columnKey, i) => {
    const config = {
      columnName: columnKey,
      wordWrapEnabled: true,
      // TODO: This whole thing is terrible
      // Data type formatters should be provided instead of doing this
    };
    if (i !== 0) {
      config.align = 'right';
    }
    if (isLarge) {
      const firstColW = 120;
      const restColsW = (width - firstColW) / (columnKeys.length - 1);
      // TODO: 2 is a filthy hack to prevent table horizontal overflow
      // No good - replace
      config.width = i === 0 ? firstColW : restColsW - 2;
    } else {
      config.width = i === 0 ? '100px' : '75px';
    }
    return { ...config };
  });
}

function getBandedColumnsFromSampleRow(row) {
  const columnKeys = Object.keys(row);
  let config = [
    {
      title: 'Confirmados',
      children: [],
    },
    {
      title: 'Muertos',
      children: [],
    },
  ];

  columnKeys.forEach((columnKey, i) => {
    let current;
    if (i > 0 && i < 7) {
      current = config[0];
    } else if (i >= 7) {
      current = config[1];
    } else {
      return;
    }
    current.children.push({ columnName: columnKey });
  });

  return config;
}

function TableRoot(props, width, height) {
  return (
    <DataGrid.Root style={{ height, width }}>{props.children}</DataGrid.Root>
  );
}

function TablePage(props) {
  const { data } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

  const [rows] = useState(getRows(data));
  const [columns] = useState(extractColumnsFromSampleRow(rows[0]));
  const [columnBands] = useState(getBandedColumnsFromSampleRow(rows[0]));
  const [leftColumns] = useState(['country']);
  const [defaultSortingState] = useState([
    { columnName: 'total-confirmed', direction: 'desc' },
  ]);

  return (
    <div>
      <div className={clsx({ [classes.contentArea]: isLarge })}>
        <Container className={classes.container}>
          <div className={classes.topContainer}>
            <Typography variant="h4" className={classes.mainTitle}>
              Tabla Comparativa
            </Typography>
            <Typography variant="caption">
              Fuente: Universidad Johns Hopkins
            </Typography>
            <br />
            <Typography variant="caption">
              Últ. actualización hace {props.lastUpdated}
            </Typography>
          </div>
          <Divider
            className={isLarge ? classes.divider : classes.dividerSmall}
          />
          <Paper className={classes.gridContainer}>
            <AutoSizer>
              {({ height, width }) => {
                const columnExtensions = getColumnExtensionsFromSampleRow(
                  rows[0],
                  isLarge,
                  width,
                );
                const compareExtensions = extendColumnExtensionsWithComparison(
                  columnExtensions,
                );
                return (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    rootComponent={props => TableRoot(props, width, height)}
                  >
                    <SortingState defaultSorting={defaultSortingState} />
                    <IntegratedSorting columnExtensions={compareExtensions} />
                    <VirtualTable
                      columnExtensions={columnExtensions}
                      height={height}
                    />
                    <TableHeaderRow showSortingControls />
                    <TableBandHeader columnBands={columnBands} />
                    <TableFixedColumns leftColumns={leftColumns} />
                  </DataGrid>
                );
              }}
            </AutoSizer>
          </Paper>
        </Container>
      </div>
    </div>
  );
}

export default withLayout(TablePage);
