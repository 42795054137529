import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import ThreeSplitView from './ThreeSplitView';
import DetailPanel from './DetailPanel';
import TwoSplitView from './TwoSplitView';
import withLayout from './withLayout';
import {
  calculateSeriesSummaryByKey,
  calculateGlobalSummary,
  getTranslationKeyList,
  sortByIsoKey,
} from '../utils/dataUtils';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import GrowthChart from './GrowthChart';
import { addGrowths } from '../utils/dataUtils';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    marginTop: theme.spacing(3),
    maxWidth: 1100,
  },
  controlBox: {
    marginBottom: theme.spacing(4),
  },
  formControl: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
  mainHeadline: {
    marginBottom: theme.spacing(3),
  },
  subHeadline: {
    marginBottom: theme.spacing(2),
  },
}));

function DetailPage(props) {
  const { data } = props;

  const [dataWithGrowths] = useState(addGrowths(data));
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState('Spain');
  const [selectedPeriod, setSelectedPeriod] = useState(14);

  const length = data[selectedCountry].length;
  const countryData = selectedPeriod
    ? dataWithGrowths[selectedCountry].filter(
        (val, i) => i >= length - selectedPeriod,
      )
    : dataWithGrowths[selectedCountry];

  const confirmedSummary = calculateSeriesSummaryByKey(
    data[selectedCountry],
    'confirmed',
  );

  const deathSummary = calculateSeriesSummaryByKey(
    data[selectedCountry],
    'deaths',
  );

  const globalSummary = calculateGlobalSummary(data[selectedCountry]);

  const [sortedTranslatedKeyList] = useState(
    getTranslationKeyList(data).sort((a, b) => {
      return a['es'] > b['es'] ? 1 : -1;
    }),
  );
  console.log('translated key list sorted: ', sortedTranslatedKeyList);

  return (
    <Container className={classes.container}>
      <Box className={classes.controlBox}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel id="country-select-label">País</InputLabel>
              <Select
                id="country-select"
                labelId="country-select-label"
                value={selectedCountry}
                onChange={e => setSelectedCountry(e.target.value)}
              >
                {sortedTranslatedKeyList.map(country => (
                  <MenuItem value={country.en} key={country.en}>
                    {country.es}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="period-select-label">Período</InputLabel>
              <Select
                id="period-select"
                labelId="period-select-label"
                value={selectedPeriod}
                onChange={e => setSelectedPeriod(e.target.value)}
              >
                <MenuItem value={0}>Todo</MenuItem>
                <MenuItem value={30}>Últ. mes</MenuItem>
                <MenuItem value={14}>Últ. 14 días</MenuItem>
                <MenuItem value={7}>Últ. semana</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="caption" align="right">
              Fuente: Universidad Johns Hopkins
            </Typography>
            {props.lastUpdated && (
              <>
                <br />
                <Typography variant="caption" align="right">
                  Últ. actualización hace {props.lastUpdated}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Divider className={classes.divider} />
      <DetailPanel panelTitle="Cuadro resumen">
        <TwoSplitView summaryData={globalSummary} chartData={countryData} />
      </DetailPanel>
      <DetailPanel panelTitle="Casos confirmados">
        <ThreeSplitView
          summaryData={confirmedSummary}
          chartData={countryData}
          leftTitle="Confirmados totales"
          rightTitle="Confirmados diarios"
          leftValueField="confirmed"
          rightValueField="newConfirmed"
        />
        <Grid item md={4} />
        <Grid item xs={12} md={8}>
          <Typography variant="h6" className={classes.subHeadline}>
            Crecimiento de casos
          </Typography>
          <GrowthChart
            data={countryData}
            argumentField="date"
            valueField="confirmedGrowth"
          />
        </Grid>
      </DetailPanel>
      <DetailPanel panelTitle="Fallecidos">
        <ThreeSplitView
          summaryData={deathSummary}
          chartData={countryData}
          leftTitle="Fallecidos totales"
          rightTitle="Fallecidos diarios"
          leftValueField="deaths"
          rightValueField="newDeaths"
        />
        <Grid item md={4} />
        <Grid item xs={12} md={8}>
          <Typography variant="h6" className={classes.subHeadline}>
            Crecimiento de fallecidos
          </Typography>
          <GrowthChart
            data={countryData}
            argumentField="date"
            valueField="deathsGrowth"
          />
        </Grid>
      </DetailPanel>
    </Container>
  );
}

export default withLayout(DetailPage);
