import React from 'react';
import RotatedLabel from './RotatedLabel';
import { ArgumentAxis } from '@devexpress/dx-react-chart-material-ui';
import { useMediaQuery, useTheme } from '@material-ui/core';

const DAY_TICK_THRESHOLD_SM = 14;
const DAY_TICK_THRESHOLD_MD = 35;

function ArgumentAxisWithSkips(props) {
  const { isLarge, origProps } = props;
  let { children } = origProps;

  const threshold = isLarge ? DAY_TICK_THRESHOLD_MD : DAY_TICK_THRESHOLD_SM;

  if (children[0].length > threshold) {
    children = [
      children[0].filter((elt, i) => i % 7 === 0),
      children[1],
      children[2].filter((elt, i) => i % 7 === 0),
    ];
  }

  return <ArgumentAxis.Root {...origProps}>{children}</ArgumentAxis.Root>;
}

export default function ModifiedArgumentAxis(props) {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const { trimDate } = props;
  return (
    <ArgumentAxis
      rootComponent={props => (
        <ArgumentAxisWithSkips origProps={props} isLarge={isLarge} />
      )}
      labelComponent={props => <RotatedLabel {...props} trimDate={trimDate} />}
    />
  );
}
