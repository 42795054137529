import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import withLayout from './withLayout';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => {
  return {
    container: {
      maxWidth: 750,
      margin: theme.spacing(4, 2),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(4, 8),
      },
    },
    mainTitle: {
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginBottom: theme.spacing(6),
    },
    paper: {
      padding: theme.spacing(3, 3),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 6),
      },
    },
    sectionHeadline: {
      marginBottom: theme.spacing(2),
    },
    section: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(6),
      },
    },
    sectionDivider: {
      maxWidth: '33%',
      marginBottom: theme.spacing(2),
    },
  };
});

function Section(props) {
  const classes = useStyles();
  return <div className={classes.section}>{props.children}</div>;
}

function SubHeadline(props) {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.sectionHeadline}>
      {props.children}
    </Typography>
  );
}

function P(props) {
  return (
    <Typography variant="body1" paragraph>
      {props.children}
    </Typography>
  );
}

function AboutPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/*<Container maxWidth="md">*/}
      <Typography variant="h4" className={classes.mainTitle}>
        Acerca de CoViz19
      </Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Section>
          <SubHeadline>Historia y misión</SubHeadline>
          <P>
            La pandemia de Covid-19 se ha convertido en una crisis global a una
            velocidad vertiginosa, y aunque los números no explican por si solos
            la tragedia, sí ayudan a comprender sus dimensiones y progresión.
          </P>
          <P>
            La misión de CoViz19 es ofrecer libre y gratuitamente una
            herramienta interactiva de visualización de datos que permita al
            usuario dar respuesta a sus propias preguntas.
          </P>
        </Section>
        <Section>
          <SubHeadline>Detalles técnicos</SubHeadline>
          <P>
            La app utiliza las series temporales de la{' '}
            <Link
              target="_blank"
              href="https://github.com/CSSEGISandData/COVID-19"
            >
              Universidad Johns Hopkins CSSE
            </Link>
            , actualizados una vez al día. Se considera una de las fuentes de
            datos más fiables a nivel mundial en estos momentos.
          </P>
          <P>Los datos de última hora son de Worldometers.info</P>
          <P>La aplicación está hecha en React, Material UI y Firebase.</P>
          <P>En breves se liberará todo el código fuente bajo licencia MIT.</P>
        </Section>
        <Section>
          <SubHeadline>Autor</SubHeadline>
          <P>
            Alex Erhardt&nbsp;&nbsp;(
            <Link href="mailto:alex@defyned.com">alex@defyned.com</Link>)
          </P>
        </Section>
        <Section>
          <SubHeadline>Colaboradores</SubHeadline>
          <P>Logotipo: Walid Boundi y Juan Luis Rousselot</P>
          <P>
            Si deseas contribuir, por favor escríbeme. Principalmente necesito
            ayuda con la programación (front, servidores, datos, scripting,
            etc.) pero también me pueden ayudar mucho contribuciones no
            técnicas, como peticiones de funcionalidades, fuentes de datos,
            ejemplos, informes de fallos, etc.
          </P>
        </Section>
        <Section>
          <SubHeadline>Agradecimientos</SubHeadline>
          <P>
            A los servicios sanitarios de España, por estar afrontando con
            coraje y determinación uno de los mayores retos de la historia
            reciente del país.
          </P>
          <P>
            A los demás servicios esenciales, como las cajeras de supermercado,
            que ponen en riesgo su salud para que nuestras necesidades básicas
            estén cubiertas.
          </P>
          <P>
            A mis padres, mi hermana Isa, mi familia y amigos por su apoyo y
            feedback.
          </P>
          <P>
            Con un agradecimiento especial a Javier Fueyo por su apoyo al
            proyecto.
          </P>
        </Section>
      </Paper>
    </div>
  );
}

export default withLayout(AboutPage);
