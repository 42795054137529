import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Chart,
  ValueAxis,
  AreaSeries,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale } from '@devexpress/dx-react-chart';
import { curveCatmullRom, area } from 'd3-shape';
import { scalePoint } from 'd3-scale';
import SupplementTable from './SupplementTable';
import ArgumentAxis from './ArgumentAxis';

const Area = props => (
  <AreaSeries.Path
    {...props}
    path={area()
      .x(({ arg }) => arg)
      .y1(({ val }) => val)
      .y0(({ startVal }) => startVal)
      .curve(curveCatmullRom)}
  />
);

export default function TwoSplitView(props) {
  const { summaryData, chartData } = props;

  return (
    <>
      <Grid container item xs={12} md={4} spacing={1}>
        <SupplementTable data={summaryData} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Chart data={chartData} height={300}>
          <ArgumentScale factory={scalePoint} />
          <ArgumentAxis />
          <ValueAxis />
          <AreaSeries
            name="Confirmados"
            valueField="confirmed"
            argumentField="date"
            seriesComponent={Area}
          />
          <AreaSeries
            name="Muertos"
            valueField="deaths"
            argumentField="date"
            seriesComponent={Area}
          />
          {window.innerWidth > 600 && <Legend position="left" />}
        </Chart>
      </Grid>
    </>
  );
}
