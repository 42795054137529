// Can further improve with:
// https://medium.com/@joeponzio/the-right-way-to-add-firebase-to-your-react-project-using-react-hooks-a32db20bf1a0
import * as firebase from 'firebase/app';

const prodConfig = {
  apiKey: 'AIzaSyC6rasFSz5R6iD7vFDLRnYF8Scz8IDp7xg',
  authDomain: 'coviz19.firebaseapp.com',
  databaseURL: 'https://coviz19.firebaseio.com',
  projectId: 'coviz19',
  storageBucket: 'coviz19.appspot.com',
  messagingSenderId: '595693028940',
  appId: '1:595693028940:web:5ca2d37359f79d41bc56e9',
  measurementId: 'G-Q7WZYCDKFW',
};

const devConfig = {
  apiKey: 'AIzaSyC6rasFSz5R6iD7vFDLRnYF8Scz8IDp7xg',
  authDomain: 'coviz19.firebaseapp.com',
  databaseURL: 'https://coviz19.firebaseio.com',
  projectId: 'coviz19',
  storageBucket: 'coviz19.appspot.com',
  messagingSenderId: '595693028940',
  appId: '1:595693028940:web:d49a2f7ff8e8e824bc56e9',
  measurementId: 'G-43C28RNY8K',
};

console.log('Environment: ', process.env.NODE_ENV);

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

firebase.initializeApp(config);

export default firebase;
