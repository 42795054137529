import React from 'react';
import { ArgumentAxis } from '@devexpress/dx-react-chart-material-ui';

function formatDate(dateStr) {
  const arr = dateStr.split('-');
  if (arr.length !== 3) {
    throw new Error('cleanupDate: Invalid date string');
  }
  let m = arr[1];
  m = m.length === 1 ? '0' + m : m;
  let d = arr[2];
  d = d.length === 1 ? '0' + d : d;
  return `${m}-${d}`;
}

export default function RotatedLabel(props) {
  const { x, y, text, dy } = props;
  // TODO: Abhorrent, clean up
  // The default should be false
  const trimDate = props.trimDate === false ? false : true;

  let newText = text;
  if (trimDate) {
    newText = formatDate(text);
  }

  return (
    <ArgumentAxis.Label
      transform={`rotate(75, ${x}, ${y})`}
      x={x}
      dy={dy}
      // Realign rotated label under tick
      y={y - 8.5}
      text={newText}
      textAnchor="start"
      style={{ fontSize: '11px' }}
    />
  );
}
