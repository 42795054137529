import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import { formatDistanceStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import axios from 'axios';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import DetailPage from './components/DetailPage';
import TablePage from './components/TablePage';
import ComparisonPage from './components/ComparisonPage';
import LastMinutePage from './components/LastMinutePage';
import AnimationPage from './components/AnimationPage';
import firebase from './utils/firebase';
import 'firebase/analytics';
import AboutPage from './components/AboutPage';
import 'typeface-ubuntu-mono';
import 'typeface-noto-sans';
import 'typeface-cousine';
import ScrollToTop from './components/ScrollToTop';

const DATA_URL = 'https://alexerhardt.github.io/covid19/timeseries.json';
const LAST_UPDATE_URL =
  'https://api.github.com/repos/CSSEGISandData/COVID-19/commits?path=csse_covid_19_data%2Fcsse_covid_19_time_series%2Ftime_series_covid19_confirmed_global.csv&page=1&per_page=1';

const dark = '#081424';
const light = '#192533';
const bodyFont = '"Noto Sans", sans';
// const mainFont = '"Ubuntu Mono", monospace';
const mainFont = '"Cousine", monospace';

const theme = createMuiTheme({
  typography: {
    fontFamily: mainFont,
    fontDisplay: 'block',
    fontSize: 13,
    button: {
      fontFamily: bodyFont,
    },
    caption: {
      fontFamily: bodyFont,
      fontSize: 12,
    },
    body1: {
      fontFamily: bodyFont,
      fontWeight: 300,
      fontSize: 14,
    },
    body2: {
      fontFamily: bodyFont,
      fontWeight: 300,
      fontSize: 14,
    },
  },
  palette: {
    type: 'dark',
    primary: { main: '#80d8ff' },
    secondary: { main: dark },
    background: {
      paper: light,
      default: dark,
    },
    table: dark,
  },
  shape: {
    borderRadius: 0,
  },
});

function calculateWorldData(data) {
  let worldData = [];
  _.forEach(data, countryTimeSeries => {
    if (worldData.length === 0) {
      worldData = [...countryTimeSeries];
    } else {
      worldData = worldData.map((dayData, i) => {
        let newDayData = { ...dayData };
        for (const key in dayData) {
          if (key !== 'date') {
            newDayData[key] += countryTimeSeries[i][key];
          }
        }
        return newDayData;
      });
    }
  });
  return worldData;
}

function App() {
  firebase.analytics();

  const [data, setData] = useState(undefined);
  const [lastUpdated, setLastUpdated] = useState(undefined);

  useEffect(() => {
    axios
      .get(DATA_URL)
      .then(res => {
        // console.log('received data: ', res.data);
        setData({ ...res.data, World: calculateWorldData(res.data) });
      })
      .catch(err => {
        console.error('error: ', err);
      });

    axios.get(LAST_UPDATE_URL).then(res => {
      if (res.data.length > 0) {
        const date = res.data[0].commit.committer.date;
        const parsed = formatDistanceStrict(new Date(), new Date(date), {
          locale: es,
        });
        setLastUpdated(parsed);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={`App`}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/compare">
              <ComparisonPage data={data} lastUpdated={lastUpdated} />
            </Route>
            <Route path="/table">
              <TablePage data={data} lastUpdated={lastUpdated} />
            </Route>
            <Route path="/about">
              {/*TODO: This component shouldn't be receiving data, fix */}
              <AboutPage data={data} lastUpdated={lastUpdated} />
            </Route>
            <Route path="/detail">
              <DetailPage data={data} lastUpdated={lastUpdated} />
            </Route>
            <Route path="/animations">
              {/*TODO: This component shouldn't be receiving data, fix */}
              <AnimationPage data={data} />
            </Route>
            <Route path="/">
              <LastMinutePage data={data} lastUpdated={lastUpdated} />
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
