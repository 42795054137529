import React from 'react';
import {
  ArgumentScale,
  EventTracker,
  HoverState,
  ValueScale,
} from '@devexpress/dx-react-chart';
import {
  Chart,
  ValueAxis,
  Tooltip,
  SplineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import ArgumentAxis from './ArgumentAxis';
import RotatedLabel from './RotatedLabel';

const PercentageTooltip = (props, data) => {
  const date = data[props.targetItem.point].date;
  const { text } = props;
  return (
    <>
      <Tooltip.Content {...props} text={date} align="center" />
      <Tooltip.Content {...props} text={text} align="center" />
    </>
  );
};

function CustomArgumentAxis(props) {
  const { children } = props;
  console.log('arg axis children: ', children);

  const newChildren = [
    children[0].filter((elt, i) => i % 7 === 0),
    children[1],
    children[2].filter((elt, i) => i % 7 === 0),
  ];

  console.log('newChildren: ', newChildren);

  return <ArgumentAxis.Root {...props}>{newChildren}</ArgumentAxis.Root>;
}

export default function LastMinuteChart(props) {
  const { data, valueField, argumentField, color } = props;

  const scaleName = 'persons';

  return (
    <Chart data={data} height={360}>
      <ArgumentAxis
        rootComponent={CustomArgumentAxis}
        labelComponent={RotatedLabel}
      />
      <ArgumentScale name="date" />
      <ValueAxis scaleName={scaleName} />
      <ValueScale name={scaleName} />
      <SplineSeries
        valueField={valueField}
        argumentField={argumentField}
        scaleName={scaleName}
        color={color}
      />
      <EventTracker />
      <HoverState />
      <Tooltip contentComponent={props => PercentageTooltip(props, data)} />
    </Chart>
  );
}
