import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  mainHeadline: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
}));

export default function DetailPanel(props) {
  const classes = useStyles();

  const { panelTitle, children } = props;

  return (
    <>
      <Typography
        variant="h4"
        className={classes.mainHeadline}
        color="textPrimary"
      >
        {panelTitle}
      </Typography>
      <Paper className={classes.paper} height={3}>
        <Grid container spacing={3}>
          {children}
        </Grid>
      </Paper>
    </>
  );
}
